import {useMemo} from 'react';
import dayjs from 'dayjs';

const useCandidateCertificateDefaultValues = (certificate = {}) => {
  const {id, authority, type, issue_date: issueDate, expiry_date: expiryDate, pc_class: pcClass, cert_type: certType} = certificate || {};

  return useMemo(() => {
    if (!certificate || !Object.keys(certificate).length) {
      return {};
    }

    const issueDateParsed = issueDate ? dayjs(issueDate) : dayjs(null);
    const expiryDateParsed = expiryDate ? dayjs(expiryDate) : dayjs(null);

    return {
      id,
      authority,
      type,
      issue_date: issueDateParsed.isValid() ? issueDateParsed : null,
      expiry_date: expiryDateParsed.isValid() ? expiryDateParsed : null,
      cert_type: certType,
      pc_class: pcClass,
    };
  }, [certificate, id, authority, type, issueDate, expiryDate, certType, pcClass]);
};

export default useCandidateCertificateDefaultValues;
